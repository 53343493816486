import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isError: true,
  errorMessage: "Hello You fail",
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.isError = true;
      console.log("action.payload is:", action.payload);
      const error = action.payload.response
        ? action.payload.response.data.message
        : action.payload;
      state.errorMessage = error;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    resetError: (state) => {
      state.errorMessage = "";
      state.isError = false;
    },
  },
});

export const { setErrorMessage, setIsError } = errorSlice.actions;
export default errorSlice.reducer;
