import {
  SettingIcon,
  DashboardIcon,
  UsersIcon,
  BettingSitesIcon,
  TeamsIcon,
  LogoutIcon,
  MatchesIcon,
  ReportIcon,
  MoonIcon,
  SunIcon,
} from "assets/icon";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//components
import Profile from "components/Profile";
//utils
import classNames from "utils/classNames";
//store
import { logout } from "store/slices/authSlice";
import { setTheme } from "store/slices/themeSlice";

export default function NavList({ isExpand = true, className }) {
  const user = useSelector((state) => state.user.user);
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const navigation = [
    { name: "Dashboard", href: "/", icon: DashboardIcon },
    { name: "Matches", href: "/matches", icon: MatchesIcon },
    { name: "Reports", href: "/reports", icon: ReportIcon },
    { name: "Teams", href: "/teams", icon: TeamsIcon },
    { name: "Agents And Lines", href: "/agents", icon: BettingSitesIcon },
    { name: "Users", href: "/users", icon: UsersIcon },
    { name: "Profile Settings", href: "/profile-setting", icon: SettingIcon },
    {
      name: "Logout",
      href: "/login",
      icon: LogoutIcon,
      onClick: () => dispatch(logout()),
    },
  ];
  return (
    <div className={className}>
      {isExpand && (
        <div className="flex items-center flex-shrink-0 px-4 pt-5">
          <Profile
            user={{
              name: user.name,
              phone: user.phone,
            }}
          />
        </div>
      )}
      <div className="mt-8 flex-1">
        <nav className="space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-wt-blue text-wt-white dark:bg-white dark:bg-opacity-10"
                    : "text-wt-dark hover:bg-wt-blue hover:text-wt-white hover:dark:bg-white hover:dark:bg-opacity-10 ",
                  "group flex items-center px-4 py-2 text-xs dark:text-white font-normal transition duration-100"
                )
              }
              onClick={item.onClick}
            >
              <item.icon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              {isExpand && <span className="ml-3">{item.name}</span>}
            </NavLink>
          ))}
        </nav>
      </div>
      <button
        onClick={() => dispatch(setTheme(theme === "light" ? "dark" : "light"))}
        className="ml-2 mt-2 mb-4 inline-flex items-center space-x-2 text-sm dark:text-white font-normal py-1 px-2 rounded focus:outline-none focus:ring-0"
      >
        {theme === "light" ? (
          <MoonIcon className="h-7 w-7 text-wt-blue" />
        ) : (
          <SunIcon className="h-6 w-6 ml-1 text-wt-white" />
        )}
      </button>
    </div>
  );
}
