import { useEffect } from "react";
import { useSelector } from "react-redux";
//routes
import Routes from "routes/Routes.jsx";

export default function App() {
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  return (
    <div className="font-manrope">
      <Routes />
    </div>
  );
}
