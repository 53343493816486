const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  overCalculatorTransactions: [],
  underCalculatorTransactions: [],
  overCalculatorTotalUnit: 0,
  underCalculatorTotalUnit: 0,
};

const calculatorTransactionsSlice = createSlice({
  name: "calculatorTransactions",
  initialState,
  reducers: {
    setOverCalculatorTransactions: (state, action) => {
      state.overCalculatorTransactions = action.payload;
    },
    setUnderCalculatorTransactions: (state, action) => {
      state.underCalculatorTransactions = action.payload;
    },
    setOverCalculatorTotalUnit: (state, action) => {
      state.overCalculatorTotalUnit = action.payload;
    },
    setUnderCalculatorTotalUnit: (state, action) => {
      state.underCalculatorTotalUnit = action.payload;
    },
  },
});

export const {
  setOverCalculatorTransactions,
  setUnderCalculatorTransactions,
  setOverCalculatorTotalUnit,
  setUnderCalculatorTotalUnit,
} = calculatorTransactionsSlice.actions;
export default calculatorTransactionsSlice.reducer;
