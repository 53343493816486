import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  overTransactions: [],
  underTransactions: [],
  overTotalUnit: 0,
  underTotalUnit: 0,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setOverTransactions: (state, action) => {
      state.overTransactions = action.payload;
    },
    setUnderTransactions: (state, action) => {
      state.underTransactions = action.payload;
    },
    setOverTotalUnit: (state, action) => {
      state.overTotalUnit = action.payload;
    },
    setUnderTotalUnit: (state, action) => {
      state.underTotalUnit = action.payload;
    },
  },
});

export const {
  setOverTransactions,
  setUnderTransactions,
  setOverTotalUnit,
  setUnderTotalUnit,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;
