import { memo } from "react";

function PageLoading() {
  return (
    <div class="rounded-md p-2 w-full h-screen max-h-screen overflow-hidden">
      <div class="animate-pulse flex space-x-3 h-full">
        <div class="rounded-sm bg-slate-100 w-16"></div>
        <div class="flex-1 flex flex-col h-full space-y-2">
          <div class="h-12 bg-slate-100 rounded"></div>
          <div className="flex-1 flex space-x-2">
            <div class="flex-1 flex flex-col space-y-2 ">
              <div className="flex-1 bg-slate-100 rounded" />
              <div className="flex-1 bg-slate-100 rounded" />
            </div>
            <div class="flex-1 max-w-md grid gap-2">
              <div className="bg-slate-100 rounded" />
              <div className="bg-slate-100 rounded" />
              <div className="bg-slate-100 rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PageLoading);
