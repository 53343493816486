const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  scoreBoardTransactions: [],
  isCalculatorScoreboard: false,
};

const scoreBoardTransactionsSlice = createSlice({
  name: "scoreBoardTransactions",
  initialState,
  reducers: {
    setScoreBoardTransactions: (state, action) => {
      state.scoreBoardTransactions = action.payload;
    },
    setIsCalculatorScoreboard: (state, action) => {
      state.isCalculatorScoreboard = action.payload;
    },
  },
});

export const { setScoreBoardTransactions, setIsCalculatorScoreboard } =
  scoreBoardTransactionsSlice.actions;
export default scoreBoardTransactionsSlice.reducer;
