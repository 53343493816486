import axios from "axios";
import { store } from "store/store";

export const service = axios.create({
  baseURL: `https://api.levelupoutsource.com/api/v1`,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
  },
});

service.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("login error: ", error);
    if (error?.response) {
      const {
        data: { status, message },
      } = error.response;
      if (status >= 400 && status < 500) {
        if (message === "invalid token") {
          store.dispatch({
            type: "auth/logout",
          });
        } else if (message === "existed") {
          return Promise.reject(error);
        } else if (message !== "incorrect password") {
          store.dispatch({
            type: "alert/setShowFailAlert",
            payload: {
              isShowAlert: true,
              failMessage: message,
              alertType: "fail",
            },
          });
        }
      }
    }
    return Promise.reject(error);
  }
);
