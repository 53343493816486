export default function Profile({ user }) {
  return (
    <div className="flex items-center space-x-2">
      <div className="avatar bg-wt-blue text-wt-white dark:bg-purple-dark dark:text-white flex items-center justify-center">
        {user?.name[0]?.toUpperCase()}
      </div>
      <div className="space-y-1">
        <p className="text-sm text-wt-dark dark:text-white font-medium pl-1">
          {user?.name}
        </p>
        <button className="btn btn-rounded text-xs">{user?.phone}</button>
      </div>
    </div>
  );
}
