import { service } from "config/service";

export const signInUser = (body) => {
  return service({
    method: "POST",
    url: "/auth/signIn",
    data: JSON.stringify(body),
  });
};

export const signUpUser = (body) =>
  service({
    method: "POST",
    url: `auth/signUp`,
    data: JSON.stringify(body),
  });

export const verifyAuthToken = () =>
  service({
    method: "GET",
    url: `auth/token/verify`,
  });
