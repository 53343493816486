const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  testScoreBoardTransactions: [],
};

const testScoreBoardSlice = createSlice({
  name: "testScoreBoardTransactions",
  initialState,
  reducers: {
    setTestScoreBoardTransactions: (state, action) => {
      state.testScoreBoardTransactions = action.payload;
    },
  },
});

export const { setTestScoreBoardTransactions } = testScoreBoardSlice.actions;
export default testScoreBoardSlice.reducer;
