import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  partialIndex: "",
  partialField: "",
  partialInitialValue: "",
  partialModifiedValue: "",
  partialUpdateLoading: false,
  closeFunc: null,
};

const partialEditSlice = createSlice({
  name: "partialEdit",
  initialState,
  reducers: {
    setPartialIndex: (state, action) => {
      state.partialIndex = action.payload;
    },
    setPartialField: (state, action) => {
      state.partialField = action.payload;
    },
    setPartialInitialValue: (state, action) => {
      state.partialInitialValue = action.payload;
    },
    setPartialModifiedValue: (state, action) => {
      state.partialModifiedValue = action.payload;
    },
    setPartialUpdateLoading: (state, action) => {
      state.partialUpdateLoading = action.payload;
    },
    setCloseFunc: (state, action) => {
      state.closeFunc = action.payload;
    },
  },
});

export const {
  setPartialIndex,
  setPartialField,
  setPartialInitialValue,
  setPartialModifiedValue,
  setPartialUpdateLoading,
  setCloseFunc,
} = partialEditSlice.actions;
export default partialEditSlice.reducer;
