import { useEffect, useState, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { Routes as RoutesConfig, Route, useNavigate } from "react-router-dom";

//api calls
import { service } from "config/service";
import { verifyAuthToken } from "api/auth";
//store
import { setUser } from "store/slices/userSlice";
import { logout, setIsAuth } from "store/slices/authSlice";
//shared
import PageLoading from "shared/PageLoading";
//containers
import ErrorBoundary from "containers/ErrorBoundary";
// routes
import AuthenticatedRoutes from "./AuthenticatedRoutes";
//pages
const Login = lazy(() => import("pages/Login"));
const Users = lazy(() => import("pages/users/Users"));
const Sites = lazy(() => import("pages/sites/Sites"));
const Teams = lazy(() => import("pages/teams/Teams"));
const Lines = lazy(() => import("pages/lines/Lines"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Agents = lazy(() => import("pages/agents/Agents"));
const Matches = lazy(() => import("pages/matches/Matches"));
const Reports = lazy(() => import("pages/reports/Reports"));
const ProfileSetting = lazy(() => import("pages/ProfileSetting"));

const token = localStorage.getItem("token");

export default function Routes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (token) {
          service.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          const response = await verifyAuthToken();
          const { status, data } = response.data;
          if (status === 200) {
            dispatch(setUser(data));
            dispatch(setIsAuth(true));
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        dispatch(logout());
      } finally {
        setLoading(false);
      }
    };
    verifyToken();
  }, []);

  if (loading) return <PageLoading />;

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <RoutesConfig>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<AuthenticatedRoutes />}>
            <Route index element={<Dashboard />} />
            <Route path="matches" element={<Matches />} />
            <Route path="teams" element={<Teams />} />
            <Route path="sites" element={<Sites />} />
            <Route path="agents" element={<Agents />} />
            <Route path="lines" element={<Lines />} />
            <Route path="reports" element={<Reports />} />
            <Route path="users" element={<Users />} />
            <Route path="profile-setting" element={<ProfileSetting />} />
          </Route>
        </RoutesConfig>
      </Suspense>
    </ErrorBoundary>
  );
}
