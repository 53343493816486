import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
//icons
import {
  MenuIcon,
  CloseIcon,
  RightCapIcon,
  LeftCapIcon,
  MoonIcon,
  SunIcon,
} from "assets/icon";
//components
import NavList from "./NavList";
//utils
import classNames from "utils/classNames";
//shared
import Alert from "shared/Alert";
import { setTheme } from "store/slices/themeSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export default function Layout({ children }) {
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isExpand, toggleExpand] = useState(false);
  const theme = useSelector((state) => state.theme);

  return (
    <div className="min-h-screen overflow-y-auto text-wt-dark bg-wt-light">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 dark:bg-gray-600 dark:bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 dark:bg-purple-light">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <CloseIcon
                      className="h-4 w-4 dark:text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <NavList />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={classNames(
          isExpand ? "md:w-64" : "md:w-16",
          "relative hidden md:flex md:flex-col md:fixed md:inset-y-0"
        )}
      >
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <NavList
          isExpand={isExpand}
          className="flex flex-col flex-grow pt-5 bg-wt-white border border-wt-gray border-opacity-10 dark:bg-purple-light overflow-y-auto custom-shadow"
        />
        {/* <button
          onClick={() =>
            dispatch(setTheme(theme === "light" ? "dark" : "light"))
          }
          className="ml-2 mb-4 inline-flex items-center space-x-2 text-sm dark:text-white font-normal py-1 px-2 border border-white dark:border-gray bg-wt-black bg-opacity-10 dark:bg-transparent rounded focus:outline-none focus:ring-0"
        >
          {theme === "light" ? (
            <MoonIcon className="h-4 w-4 " />
          ) : (
            <SunIcon className="h-4 w-4" />
          )}
        </button> */}
        <button
          onClick={() => toggleExpand((prev) => !prev)}
          className="absolute top-2 right-0 transform translate-x-1/2 flex items-center justify-center h-8 w-8 rounded-full bg-wt-blue dark:bg-white cursor-pointer"
        >
          {isExpand ? (
            <LeftCapIcon className="w-5 h-5 text-white dark:text-purple-dark" />
          ) : (
            <RightCapIcon className="w-5 h-5 text-white dark:text-purple-dark" />
          )}
        </button>
      </div>
      {/* End Static sidebar for desktop */}

      <div
        className={classNames(
          isExpand ? "md:pl-64" : "md:pl-16",
          "flex flex-col flex-1 dark:bg-purple-dark h-full"
        )}
      >
        <main className="px-6">
          <button
            onClick={() => setSidebarOpen(true)}
            className="fixed top-2 left-2 md:hidden border-none focus:outline-none focus:ring-0"
          >
            <MenuIcon className="dark:text-white h-6 w-6" />
          </button>
          <div className="w-full h-full">{children}</div>
        </main>
      </div>
      <Alert />
    </div>
  );
}
