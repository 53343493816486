const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  testOverCalculatorTransactions: [],
  testUnderCalculatorTransactions: [],
  testOverCalculatorTotalUnit: 0,
  testUnderCalculatorTotalUnit: 0,
};

const testCalculatorSlice = createSlice({
  name: "testCalculatorTransactions",
  initialState,
  reducers: {
    setTestOverCalculatorTransactions: (state, action) => {
      state.testOverCalculatorTransactions = action.payload;
    },
    setTestUnderCalculatorTransactions: (state, action) => {
      state.testUnderCalculatorTransactions = action.payload;
    },
    setTestOverCalculatorTotalUnit: (state, action) => {
      state.testOverCalculatorTotalUnit = action.payload;
    },
    setTestUnderCalculatorTotalUnit: (state, action) => {
      state.testUnderCalculatorTotalUnit = action.payload;
    },
  },
});

export const {
  setTestOverCalculatorTransactions,
  setTestUnderCalculatorTransactions,
  setTestOverCalculatorTotalUnit,
  setTestUnderCalculatorTotalUnit,
} = testCalculatorSlice.actions;
export default testCalculatorSlice.reducer;
