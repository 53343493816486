import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

const ErrorComponent = () => (
  <div className="h-screen max-h-screen flex flex-col items-center justify-center space-y-3">
    <h1 className="text-5xl text-wt-dark dark:text-white font-bold">Oops!</h1>
    <h3 className="text-2xl text-wt-dark dark:text-white font-medium">
      Something went wrong
    </h3>
  </div>
);
