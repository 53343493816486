const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isOpenPortal: false,
};

const openPortalSlice = createSlice({
  name: "openPortal",
  initialState,
  reducers: {
    setIsOpenPortal: (state, action) => {
      state.isOpenPortal = action.payload;
    },
  },
});

export const { setIsOpenPortal } = openPortalSlice.actions;
export default openPortalSlice.reducer;
