import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import authReducer from "./slices/authSlice";
import errorReducer from "./slices/errorSlice";
import teamsReducer from "./slices/teamsSlice";
import alertReducer from "./slices/alertSlice";
import themeReducer from "./slices/themeSlice";
import openPortalReducer from "./slices/openPortalSlice";
import partialEditReducer from "./slices/partialEditSlice";
import transactionsReducer from "./slices/transactionsSlice";
import scoreBoardTransactionsReducer from "./slices/scoreBoardTransactionsSlice";
import calculatorTransactionsReducer from "./slices/calculatorTransactionsSlice";
import testScoreBoardSliceReducer from "./slices/testScoreBoardSlice";
import testCalculatorSliceReducer from "./slices/testCalculatorSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    error: errorReducer,
    teams: teamsReducer,
    alert: alertReducer,
    theme: themeReducer,
    openPortal: openPortalReducer,
    partialEdit: partialEditReducer,
    transactions: transactionsReducer,
    scoreBoardTransactions: scoreBoardTransactionsReducer,
    calculatorTransactions: calculatorTransactionsReducer,
    testScoreBoardTransactions: testScoreBoardSliceReducer,
    testCalculatorTransactions: testCalculatorSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
