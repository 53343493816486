const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  overTeam: "",
  underTeam: "",
};

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    setOverTeam: (state, action) => {
      state.overTeam = action.payload;
    },
    setUnderTeam: (state, action) => {
      state.underTeam = action.payload;
    },
    resetTeam: (state) => {
      state.overTeam = "";
      state.underTeam = "";
    },
  },
});

export const { setOverTeam, setUnderTeam, resetTeam } = teamsSlice.actions;
export default teamsSlice.reducer;
