import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
//containers
import Layout from "containers/Layout";

export default function AuthenticatedRoutes() {
  const isAuth = useSelector((state) => state.auth.isAuth);

  return isAuth ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" replace={true} />
  );
}
