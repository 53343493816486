export const DashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Group_74" data-name="Group 74" transform="translate(-49 -170)">
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="24"
        height="24"
        transform="translate(49 170)"
        fill="none"
      />
      <g
        id="_16287176351571183080"
        data-name="16287176351571183080"
        transform="translate(49.505 170.167)"
      >
        <path
          id="Path_3248"
          data-name="Path 3248"
          d="M4.577,19.667a.678.678,0,0,1-.537-.264A9.593,9.593,0,0,1,2,13.495,9.5,9.5,0,0,1,18.214,6.776a9.436,9.436,0,0,1,2.776,6.719,9.227,9.227,0,0,1-2.052,5.923.678.678,0,0,1-.8.189,16.239,16.239,0,0,0-6.639-1.364,16.239,16.239,0,0,0-6.639,1.364A.677.677,0,0,1,4.577,19.667Zm6.917-2.781a17.785,17.785,0,0,1,6.7,1.271,7.947,7.947,0,0,0,1.438-4.662A8.139,8.139,0,1,0,4.807,18.152,17.788,17.788,0,0,1,11.495,16.886Z"
          fill="currentColor"
        />
        <path
          id="Line_4"
          data-name="Line 4"
          d="M-.322,1.713A.678.678,0,0,1-1,1.035V-.322A.678.678,0,0,1-.322-1a.678.678,0,0,1,.678.678V1.035A.678.678,0,0,1-.322,1.713Z"
          transform="translate(11.817 7.035)"
          fill="currentColor"
        />
        <path
          id="Line_5"
          data-name="Line 5"
          d="M.628,1.306a.676.676,0,0,1-.48-.2L-.8.158A.678.678,0,0,1-.8-.8a.678.678,0,0,1,.959,0l.949.949a.678.678,0,0,1-.48,1.158Z"
          transform="translate(7.001 9.001)"
          fill="currentColor"
        />
        <path
          id="Line_6"
          data-name="Line 6"
          d="M1.035.356H-.322A.678.678,0,0,1-1-.322.678.678,0,0,1-.322-1H1.035a.678.678,0,0,1,.678.678A.678.678,0,0,1,1.035.356Z"
          transform="translate(5.035 13.817)"
          fill="currentColor"
        />
        <path
          id="Line_7"
          data-name="Line 7"
          d="M1.035.356H-.322A.678.678,0,0,1-1-.322.678.678,0,0,1-.322-1H1.035a.678.678,0,0,1,.678.678A.678.678,0,0,1,1.035.356Z"
          transform="translate(17.242 13.817)"
          fill="currentColor"
        />
        <path
          id="Line_8"
          data-name="Line 8"
          d="M-.322,1.306a.676.676,0,0,1-.48-.2A.678.678,0,0,1-.8.148L.148-.8a.678.678,0,0,1,.959,0,.678.678,0,0,1,0,.959l-.949.949A.676.676,0,0,1-.322,1.306Z"
          transform="translate(15.683 9.001)"
          fill="currentColor"
        />
        <path
          id="Line_9"
          data-name="Line 9"
          d="M-.322,5.782a.675.675,0,0,1-.3-.072.678.678,0,0,1-.3-.91L1.784-.625a.678.678,0,0,1,.91-.3.678.678,0,0,1,.3.91L.285,5.407A.678.678,0,0,1-.322,5.782Z"
          transform="translate(11.817 12.46)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export const UsersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.5"
    height="15.417"
    viewBox="0 0 18.5 15.417"
    {...props}
  >
    <g
      id="_13251028091543238941"
      data-name="13251028091543238941"
      transform="translate(0 -2)"
    >
      <path
        id="Path_3248"
        data-name="Path 3248"
        d="M13.1,20.167a.771.771,0,0,1-.771-.771V17.854a2.315,2.315,0,0,0-2.312-2.313H3.854a2.315,2.315,0,0,0-2.313,2.313V19.4A.771.771,0,0,1,0,19.4V17.854A3.859,3.859,0,0,1,3.854,14h6.167a3.859,3.859,0,0,1,3.854,3.854V19.4A.771.771,0,0,1,13.1,20.167Z"
        transform="translate(0 -2.75)"
        fill="currentColor"
      />
      <path
        id="Ellipse_23"
        data-name="Ellipse 23"
        d="M2.854-1A3.854,3.854,0,1,1-1,2.854,3.859,3.859,0,0,1,2.854-1Zm0,6.167A2.313,2.313,0,1,0,.542,2.854,2.315,2.315,0,0,0,2.854,5.167Z"
        transform="translate(4.083 3)"
        fill="currentColor"
      />
      <path
        id="Path_3249"
        data-name="Path 3249"
        d="M22.083,20.2a.771.771,0,0,1-.771-.771V17.884a2.312,2.312,0,0,0-1.734-2.237.771.771,0,0,1,.385-1.493,3.854,3.854,0,0,1,2.891,3.729v1.542A.771.771,0,0,1,22.083,20.2Z"
        transform="translate(-4.354 -2.78)"
        fill="currentColor"
      />
      <path
        id="Path_3250"
        data-name="Path 3250"
        d="M15.77,9.646a.771.771,0,0,1-.191-1.518,2.313,2.313,0,0,0,0-4.48.771.771,0,1,1,.382-1.493,3.854,3.854,0,0,1,0,7.467A.772.772,0,0,1,15.77,9.646Z"
        transform="translate(-3.437 -0.03)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const BettingSitesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.323"
    height="17.323"
    viewBox="0 0 17.323 17.323"
    {...props}
  >
    <g
      id="_3782943391595759944"
      data-name="3782943391595759944"
      transform="translate(-2 -2)"
    >
      <path
        id="Ellipse_23"
        data-name="Ellipse 23"
        d="M7.661-1a8.661,8.661,0,0,1,8.661,8.661,8.661,8.661,0,0,1-8.661,8.661A8.661,8.661,0,0,1-1,7.661,8.661,8.661,0,0,1,7.661-1Zm0,16.086A7.424,7.424,0,1,0,.237,7.661,7.433,7.433,0,0,0,7.661,15.086Z"
        transform="translate(3 3)"
        fill="currentColor"
      />
      <path
        id="Path_3248"
        data-name="Path 3248"
        d="M13.331,10a.618.618,0,0,1,.355.112l3.712,2.6a.619.619,0,0,1,.231.705l-1.423,4.207a.619.619,0,0,1-.586.42H11.042a.619.619,0,0,1-.586-.42L9.033,13.415a.619.619,0,0,1,.231-.705l3.712-2.6A.618.618,0,0,1,13.331,10Zm2.977,3.458-2.977-2.084-2.977,2.084,1.132,3.347h3.691Z"
        transform="translate(-2.669 -3.051)"
        fill="currentColor"
      />
      <path
        id="Path_3249"
        data-name="Path 3249"
        d="M21.619,12.988a.619.619,0,0,1-.277-1.172L23.564,10.7l.549-2.139a.619.619,0,0,1,1.2.307l-.619,2.413a.619.619,0,0,1-.323.4L21.9,12.922A.616.616,0,0,1,21.619,12.988Z"
        transform="translate(-7.245 -2.326)"
        fill="currentColor"
      />
      <path
        id="Path_3250"
        data-name="Path 3250"
        d="M14.093,8.568a.619.619,0,0,1-.619-.619V5.238L11.342,4.172A.619.619,0,1,1,11.9,3.065L14.37,4.3a.619.619,0,0,1,.342.553V7.949A.619.619,0,0,1,14.093,8.568Z"
        transform="translate(-3.432 -0.381)"
        fill="currentColor"
      />
      <path
        id="Line_4"
        data-name="Line 4"
        d="M-.381,1.475a.619.619,0,0,1-.554-.342A.619.619,0,0,1-.658.3L1.817-.935a.619.619,0,0,1,.83.277.619.619,0,0,1-.277.83L-.1,1.409A.616.616,0,0,1-.381,1.475Z"
        transform="translate(11.043 3.619)"
        fill="currentColor"
      />
      <path
        id="Line_5"
        data-name="Line 5"
        d="M1.412,1.351a.616.616,0,0,1-.326-.093L-.708.144a.619.619,0,0,1-.2-.852.619.619,0,0,1,.852-.2L1.739.207a.619.619,0,0,1-.327,1.144Z"
        transform="translate(17.23 9.187)"
        fill="currentColor"
      />
      <path
        id="Path_3251"
        data-name="Path 3251"
        d="M7.611,13.026a.616.616,0,0,1-.276-.065L4.861,11.723a.619.619,0,0,1-.322-.4L3.92,8.976a.619.619,0,1,1,1.2-.315l.547,2.08,2.224,1.112a.619.619,0,0,1-.277,1.172Z"
        transform="translate(-0.724 -2.364)"
        fill="currentColor"
      />
      <path
        id="Line_6"
        data-name="Line 6"
        d="M-.381,1.289A.618.618,0,0,1-.91.991.619.619,0,0,1-.7.142L1.03-.91A.619.619,0,0,1,1.88-.7a.619.619,0,0,1-.208.85L-.06,1.2A.616.616,0,0,1-.381,1.289Z"
        transform="translate(3.062 9.187)"
        fill="currentColor"
      />
      <path
        id="Path_3252"
        data-name="Path 3252"
        d="M7.826,23.475a.619.619,0,0,1-.078,0L4.84,23.1A.619.619,0,0,1,5,21.871L7.6,22.2l1.023-1.023a.619.619,0,0,1,.875.875L8.264,23.294A.619.619,0,0,1,7.826,23.475Z"
        transform="translate(-0.877 -7.245)"
        fill="currentColor"
      />
      <path
        id="Line_7"
        data-name="Line 7"
        d="M.856,2.712A.619.619,0,0,1,.3,2.37L-.935-.1a.619.619,0,0,1,.277-.83.619.619,0,0,1,.83.277L1.409,1.817a.619.619,0,0,1-.553.9Z"
        transform="translate(7.331 15.992)"
        fill="currentColor"
      />
      <path
        id="Path_3253"
        data-name="Path 3253"
        d="M20.856,23.475a.619.619,0,0,1-.437-.181l-1.237-1.237a.619.619,0,0,1,.875-.875l1.028,1.028,2.551-.277a.619.619,0,1,1,.134,1.23l-2.846.309A.62.62,0,0,1,20.856,23.475Z"
        transform="translate(-6.483 -7.245)"
        fill="currentColor"
      />
      <path
        id="Line_8"
        data-name="Line 8"
        d="M-.382,2.712a.616.616,0,0,1-.276-.065.619.619,0,0,1-.277-.83L.3-.658a.619.619,0,0,1,.83-.277.619.619,0,0,1,.277.83L.172,2.37A.619.619,0,0,1-.382,2.712Z"
        transform="translate(13.518 15.992)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const MatchesIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 491.8 491.8"
    enableBackground="new 0 0 491.8 491.8"
    {...props}
  >
    <g>
      <path
        className="st3"
        fill="currentColor"
        d="M144.6,203.6c-2.2,0.7-6.5-1.3-6.1,2.8c0.4,3.7,2.3,9,6.2,9.1c4.1,0.2,5.9-5.3,6.4-8.8   C151.7,201.9,147,204.5,144.6,203.6z"
      />
      <path
        className="st3"
        fill="currentColor"
        d="M145,156.6c-4.2-0.3-5.7,5.1-6.4,8.6c-1,5.3,3.9,2.3,6.5,3.6c1.7-1.7,6.6,1.8,6.1-3.2   C150.9,162,148.9,156.8,145,156.6z"
      />
      <path
        className="st3"
        fill="currentColor"
        d="M457.2,361.3c-1.1-10.1-2.7-24.6-4.3-39.1c-5.7-50.4-10.4-100.9-17.5-151c-7.9-55.9-69.6-83.7-117.8-54.3   c-4.4,2.7-8.7,4-13.9,4c-33.5-0.1-67-0.2-100.4,0.1c-6.6,0.1-12-1.6-17.6-4.9c-48.6-28.8-110.3,1.6-117,57.6   c-5.9,50-11.8,100-17.1,150.1c-1.9,18-6.1,36-2.5,54.4c5.7,29.6,32.4,48.1,60.9,41c20.4-5.1,32.4-19.8,40.3-38.2   c8.9-21,17.3-42.2,25.7-63.5c1.9-4.7,3.8-7.2,9.7-7.5c16.7-1,28.8-9.7,34.8-25.2c2.3-5.8,5.2-7.1,10.8-6.9   c14.2,0.4,28.5,0.5,42.8,0.1c5.5-0.1,7.9,1.5,10,6.7c6.4,15.3,18.2,24.3,34.9,25.3c5.7,0.3,8.1,2.4,9.9,7.3   c4,10.9,8.3,21.6,12.9,32.2c6.9,16.1,11.6,33.1,22.3,47.5c16.1,21.8,35.9,28.6,59.9,20.4C446.5,409.5,457.6,392.5,457.2,361.3z    M139.1,223.7c-8.4,0.3-12.6-3.9-11.8-12.1c0.7-6.9-2.1-8.5-8.3-8c-8.4,0.7-12.1-4-11.7-12.2c0.1-3.3,0.1-6.6,0-9.9   c-0.5-9.1,3.4-13.7,12.9-13.1c2.7,0.1,6.4,1.9,7.1-3.9c2.1-16,2.4-15.9,17.8-16c15.2,0,15.5-0.1,17.2,15.6c0.6,5.6,3.9,4.5,6.8,4.3   c10-0.7,14,4.1,13.3,13.8c-0.2,3.3-0.2,6.6,0,9.9c0.3,7.9-3.6,12.1-11.3,11.5c-6.8-0.5-9.5,1.3-8.7,8.5c0.8,7.8-3.6,11.6-11.3,11.5   C147.1,223.6,143.1,223.5,139.1,223.7z M182.9,293.6c-13.8,0.2-25.1-11.2-25-25.1c0.1-13.6,11-24.4,24.6-24.4   c13.4,0,24.5,11,24.6,24.6C207.3,282.1,196.3,293.3,182.9,293.6z M324.7,168.5c8.9-0.1,16.6,7.5,16.5,16.3   c0,8.4-7.2,15.8-15.7,16.1c-9.1,0.3-16.7-6.7-17-15.7C308.3,176.3,315.8,168.6,324.7,168.5z M347,269.5   c-0.5,13.4-11.9,24.3-25.3,24.1c-13.4-0.2-24.3-11.4-24.2-25c0.1-13.5,11.1-24.5,24.6-24.5C335.8,244,347.5,255.9,347,269.5z    M358.6,234.5c-8.7,0.1-15.9-6.7-16.4-15.3c-0.5-8.8,6.8-16.7,15.7-17c9-0.3,16.7,6.9,17,15.8C375.1,226.9,367.6,234.5,358.6,234.5   z M359.6,165.9c-8.9-0.2-16.2-8.1-15.9-17c0.4-8.5,7.7-15.7,16.1-15.7c8.9,0,16.5,7.6,16.4,16.5   C376.1,158.7,368.4,166.2,359.6,165.9z M393.1,199.5c-8.5-0.3-15.6-7.5-15.8-16c-0.2-8.8,7.5-16.7,16.2-16.6   c9,0,16.2,7.4,16.1,16.6C409.6,192.6,402.1,199.8,393.1,199.5z"
      />
      <path
        className="st3"
        fill="currentColor"
        d="M165.6,192.2c3.5-0.3,8.8-2.3,8.7-6.3c-0.1-3.9-5.4-5.5-8.9-6.3c-4.3-1-2.4,3.2-3,5.5   C163.7,187.4,159.9,192.7,165.6,192.2z"
      />
      <path
        className="st3"
        fill="currentColor"
        d="M124.2,179.7c-3.6,0.4-8.8,2.4-9.1,6.1c-0.2,4,5.1,5.7,8.6,6.4c5,1,2.7-3.7,3.7-6.5   C126.1,184,129,179.2,124.2,179.7z"
      />
    </g>
  </svg>
);

export const TeamsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 128 128"
    {...props}
  >
    <defs></defs>
    <title>b</title>
    <path
      className="cls-1"
      fill="currentColor"
      d="M95.30418,29.027q-.31725-.89591-.68736-1.76834A33.27932,33.27932,0,0,0,64.23794,6.9434c-.07834-.002-.15862-.002-.237-.002s-.16058,0-.23891.002A33.28,33.28,0,0,0,33.38123,27.25865q-.36718.87243-.6854,1.76834a33.24338,33.24338,0,0,0,0,22.28528q.31725.89591.68736,1.76638A33.41611,33.41611,0,0,0,47.50243,69.00147a15.87009,15.87009,0,0,1,1.52551-3.56016A29.5599,29.5599,0,0,1,37.60918,53.07865h9.64259a42.29476,42.29476,0,0,0,3.49749,9.952c.01567-.01958.03133-.03916.049-.05875a15.87171,15.87171,0,0,1,2.92568-2.61431,39.90935,39.90935,0,0,1-2.5066-7.27894H62.07794v4.46293c.235-.01175.47-.01763.707-.01763h1.93087c.40341,0,.80681.01567,1.2063.047V53.07865H76.78075A39.425,39.425,0,0,1,74.14882,60.622a15.82091,15.82091,0,0,1,2.5536,2.34994c.12142.139.24088.282.35641.425.03721-.06855.07442-.13708.10966-.20758a42.06253,42.06253,0,0,0,3.57779-10.11063h9.64456A29.59066,29.59066,0,0,1,78.598,65.66063a15.8627,15.8627,0,0,1,1.46871,3.58562,33.43876,33.43876,0,0,0,14.55006-16.1676q.37012-.86949.68736-1.76638a33.24338,33.24338,0,0,0,0-22.28528ZM46.53307,49.23454H36.05038a29.3789,29.3789,0,0,1,0-18.12981h10.4827a61.86142,61.86142,0,0,0,0,18.12981Zm-8.9239-21.97588A29.53778,29.53778,0,0,1,53.718,12.644a27.09964,27.09964,0,0,0-2.88847,4.506,42.21577,42.21577,0,0,0-3.57778,10.10867ZM62.07794,49.23454h-11.64a56.91567,56.91567,0,0,1,0-18.12981h11.64Zm0-21.97588H51.2173c2.09145-8.52245,6.151-14.82226,10.86064-16.19306Zm3.84412-16.19306c4.70967,1.36884,8.76725,7.67061,10.85869,16.19306H65.92206Zm11.64,38.16895h-11.64V31.10473h11.64a56.91475,56.91475,0,0,1,0,18.12981ZM77.16849,17.15A27.09887,27.09887,0,0,0,74.28,12.644,29.53372,29.53372,0,0,1,90.39083,27.25865H80.74628A42.04677,42.04677,0,0,0,77.16849,17.15ZM91.95159,49.23454H81.46693a62.04048,62.04048,0,0,0,0-18.12981H91.95159a29.45554,29.45554,0,0,1,0,18.12981Z"
    />
    <path
      className="cls-2"
      fill="currentColor"
      d="M87.55651,102.44087c-5.37867-2.68129-17.1831-6.64366-17.1831-6.64366h-.99866l-3.23,4.30939L69.173,121.05836H91.73473a124.92982,124.92982,0,0,0-1.0854-14.45139A5.74475,5.74475,0,0,0,87.55651,102.44087Z"
    />
    <path
      className="cls-2"
      fill="currentColor"
      d="M74.20242,83.33l1.62545-8.3783a11.21614,11.21614,0,0,0-11.112-12.72758H62.78508a11.21614,11.21614,0,0,0-11.112,12.72758L53.2985,83.33a10.64786,10.64786,0,0,0,20.90391,0Z"
    />
    <path
      className="cls-2"
      fill="currentColor"
      d="M57.12751,95.7972s-11.80443,3.96237-17.1831,6.64366a5.74475,5.74475,0,0,0-3.09283,4.16611,124.92982,124.92982,0,0,0-1.0854,14.45139H58.32793l3.02828-20.95177-3.23-4.30939Z"
    />
    <path
      className="cls-2"
      fill="currentColor"
      d="M125.5401,121.05856h-29.104l-.002-.0842a128.30016,128.30016,0,0,0-1.15342-15.16692,10.42143,10.42143,0,0,0-2.01116-4.58238c2.02291-.7187,3.45637-1.20043,3.45637-1.20043V96.77974l-.27415-.20758a9.38777,9.38777,0,0,1-3.566-5.9669l-.05483-.34466h-.26634A3.626,3.626,0,0,1,89.208,88.0027a3.95839,3.95839,0,0,1-.49349-1.91325,3.63789,3.63789,0,0,1,.2585-1.34534,1.87846,1.87846,0,0,1,.72653-1.16909l.90668-.55224-.2252-.98306c-1.63712-7.1399,3.895-13.57288,11.32085-13.74911.18212-.00392.36032-.00588.53853-.002.17625-.00392.35445-.002.53657.002,7.42777.17624,12.958,6.60921,11.32085,13.74911l-.2252.98306.90668.55224a1.87279,1.87279,0,0,1,.72849,1.16909,3.63625,3.63625,0,0,1,.25653,1.34534,3.93674,3.93674,0,0,1-.49349,1.91325,3.626,3.626,0,0,1-3.35649,2.2579H111.648l-.05483.34466a9.38777,9.38777,0,0,1-3.566,5.9669l-.2722.20758v3.24488s9.82667,3.29775,14.30528,5.53216a4.77359,4.77359,0,0,1,2.57514,3.46812A104.12982,104.12982,0,0,1,125.5401,121.05856Z"
    />
    <path
      className="cls-2"
      fill="currentColor"
      d="M39.28547,86.08946A3.95839,3.95839,0,0,1,38.792,88.0027a3.62874,3.62874,0,0,1-3.35846,2.2579h-.26632l-.05483.34466a9.38524,9.38524,0,0,1-3.56408,5.9669l-.27416.20758v3.24488s1.25135.41906,3.06472,1.06139a10.45875,10.45875,0,0,0-2.11887,4.7273,128.147,128.147,0,0,0-1.15343,15.161l-.002.0842H2.4599a103.94864,103.94864,0,0,1,.90278-12.03367,4.77846,4.77846,0,0,1,2.57514-3.46812c4.4786-2.23441,14.30723-5.53216,14.30723-5.53216V96.77974l-.27415-.20758a9.38519,9.38519,0,0,1-3.56408-5.9669L16.352,90.2606h-.26632a3.62871,3.62871,0,0,1-3.35845-2.2579,3.95839,3.95839,0,0,1-.49349-1.91325,3.63765,3.63765,0,0,1,.2585-1.34534,1.87282,1.87282,0,0,1,.72848-1.16909l.90669-.55224-.2252-.98306c-1.6391-7.1399,3.89306-13.57288,11.32085-13.74911.18016-.00392.35836-.00588.53657-.002.17624-.00392.35641-.002.53657.002,7.42777.17624,12.95993,6.60921,11.32085,13.74911l-.22521.98306.90669.55224a1.87279,1.87279,0,0,1,.72849,1.16909A3.63789,3.63789,0,0,1,39.28547,86.08946Z"
    />
  </svg>
);

export const SettingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Group_110" data-name="Group 110" transform="translate(-49 -170)">
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="24"
        height="24"
        transform="translate(49 170)"
        fill="none"
      />
      <g id="setting-config-svgrepo-com" transform="translate(50.497 171.538)">
        <path
          id="Path_3254"
          data-name="Path 3254"
          d="M36.929,9.715H34.357a.857.857,0,1,1,0-1.715h2.572a.857.857,0,0,1,0,1.715Z"
          transform="translate(-19.139 -4.397)"
          fill="currentColor"
        />
        <path
          id="Path_3255"
          data-name="Path 3255"
          d="M26.357,9.144a.857.857,0,0,1-.857-.857V4.857a.857.857,0,1,1,1.715,0V8.287A.857.857,0,0,1,26.357,9.144Z"
          transform="translate(-14.569 -2.112)"
          fill="currentColor"
        />
        <path
          id="Path_3256"
          data-name="Path 3256"
          d="M13.788,9.715H4.357A.857.857,0,0,1,4.357,8h9.431a.857.857,0,1,1,0,1.715Z"
          transform="translate(-2 -4.397)"
          fill="currentColor"
        />
        <path
          id="Path_3257"
          data-name="Path 3257"
          d="M7.787,23.715H4.357a.857.857,0,1,1,0-1.715H7.787a.857.857,0,0,1,0,1.715Z"
          transform="translate(-2 -12.396)"
          fill="currentColor"
        />
        <path
          id="Path_3258"
          data-name="Path 3258"
          d="M20.357,23.144a.857.857,0,0,1-.857-.857V18.857a.857.857,0,0,1,1.715,0v3.429A.857.857,0,0,1,20.357,23.144Z"
          transform="translate(-11.141 -10.11)"
          fill="currentColor"
        />
        <path
          id="Path_3259"
          data-name="Path 3259"
          d="M29.788,23.715H20.357a.857.857,0,1,1,0-1.715h9.431a.857.857,0,1,1,0,1.715Z"
          transform="translate(-11.141 -12.396)"
          fill="currentColor"
        />
        <path
          id="Path_3260"
          data-name="Path 3260"
          d="M36.929,37.715H34.357a.857.857,0,1,1,0-1.715h2.572a.857.857,0,0,1,0,1.715Z"
          transform="translate(-19.139 -20.394)"
          fill="currentColor"
        />
        <path
          id="Path_3261"
          data-name="Path 3261"
          d="M26.357,37.144a.857.857,0,0,1-.857-.857V32.857a.857.857,0,0,1,1.715,0v3.429A.857.857,0,0,1,26.357,37.144Z"
          transform="translate(-14.569 -18.109)"
          fill="currentColor"
        />
        <path
          id="Path_3262"
          data-name="Path 3262"
          d="M13.788,37.715H4.357a.857.857,0,1,1,0-1.715h9.431a.857.857,0,1,1,0,1.715Z"
          transform="translate(-2 -20.394)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export const LogoutIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Group_109" data-name="Group 109" transform="translate(-49 -170)">
      <rect
        id="Rectangle_43"
        data-name="Rectangle 43"
        width="24"
        height="24"
        transform="translate(49 170)"
        fill="none"
      />
      <g id="XMLID_6_" transform="translate(51.624 149.09)">
        <path
          id="XMLID_7_"
          d="M3,104.4H13.184a.879.879,0,1,0,0-1.758H3L4.138,101.5A.879.879,0,0,0,2.9,100.258L.259,102.895q-.031.031-.058.065l-.023.03c-.01.013-.02.025-.029.039s-.015.025-.023.037-.015.024-.021.036-.013.026-.019.039-.012.025-.018.039-.01.026-.014.039-.01.028-.015.042-.007.026-.01.039-.008.029-.011.044,0,.031-.007.046,0,.026-.006.039c0,.028,0,.057,0,.085H0q0,.043,0,.085c0,.013,0,.026.006.039s0,.031.007.046.007.029.011.044.006.027.01.04.01.028.015.042.009.027.014.04.012.025.018.038.012.027.019.04.014.023.021.035.015.026.023.039.018.025.027.037.015.022.024.032.036.042.055.061l0,0L2.9,106.774a.879.879,0,0,0,1.243-1.243Z"
          transform="translate(0 -70.606)"
          fill="currentColor"
        />
        <path
          id="XMLID_8_"
          d="M112.014,25H100.881a.879.879,0,0,0-.879.879v3.516a.879.879,0,1,0,1.758,0V26.758h9.375v12.3H101.76V36.426a.879.879,0,1,0-1.758,0v3.516a.879.879,0,0,0,.879.879h11.133a.879.879,0,0,0,.879-.879V25.879A.879.879,0,0,0,112.014,25Z"
          transform="translate(-94.142)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    id="close-button"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="close">
      <path
        id="Path_3337"
        data-name="Path 3337"
        d="M24,2.4,21.6,0,12,9.6,2.4,0,0,2.4,9.6,12,0,21.6,2.4,24,12,14.4,21.6,24,24,21.6,14.4,12Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-menu"
    {...props}
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

export const ExpandIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className="typo-title3 -rotate-90"
    {...props}
  >
    <path
      d="M12 10.545L7.176 15.37a1.86 1.86 0 11-2.631-2.631l6.077-6.077a1.949 1.949 0 012.756 0l6.077 6.077a1.86 1.86 0 11-2.631 2.63L12 10.546z"
      fill="currentcolor"
      fillRule="evenodd"
    ></path>
  </svg>
);

export const RightCapIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 32 32"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      d="M24.291,14.276L14.705,4.69c-0.878-0.878-2.317-0.878-3.195,0l-0.8,0.8c-0.878,0.877-0.878,2.316,0,3.194  L18.024,16l-7.315,7.315c-0.878,0.878-0.878,2.317,0,3.194l0.8,0.8c0.878,0.879,2.317,0.879,3.195,0l9.586-9.587  c0.472-0.471,0.682-1.103,0.647-1.723C24.973,15.38,24.763,14.748,24.291,14.276z"
      fill="currentColor"
    />
  </svg>
);

export const LeftCapIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 32 32"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      d="M7.701,14.276l9.586-9.585c0.879-0.878,2.317-0.878,3.195,0l0.801,0.8c0.878,0.877,0.878,2.316,0,3.194  L13.968,16l7.315,7.315c0.878,0.878,0.878,2.317,0,3.194l-0.801,0.8c-0.878,0.879-2.316,0.879-3.195,0l-9.586-9.587  C7.229,17.252,7.02,16.62,7.054,16C7.02,15.38,7.229,14.748,7.701,14.276z"
      fill="currentColor"
    />
  </svg>
);

export const SelectIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 7" {...props}>
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M5,0l5,7H0Z"
      transform="translate(10 7) rotate(180)"
      fill="currentColor"
    />
  </svg>
);

export const CheckedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 80.588 61.158"
    enableBackground="new 0 0 80.588 61.158"
    {...props}
  >
    <path
      fill="currentColor"
      d="M29.658,61.157c-1.238,0-2.427-0.491-3.305-1.369L1.37,34.808c-1.826-1.825-1.826-4.785,0-6.611  c1.825-1.826,4.786-1.827,6.611,0l21.485,21.481L72.426,1.561c1.719-1.924,4.674-2.094,6.601-0.374  c1.926,1.72,2.094,4.675,0.374,6.601L33.145,59.595c-0.856,0.959-2.07,1.523-3.355,1.56C29.746,61.156,29.702,61.157,29.658,61.157z  "
    />
  </svg>
);

export const PlusIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Icons" transform="translate(5 5)">
      <g id="Outlined" transform="translate(-105 -1487)">
        <g id="Content" transform="translate(100 1428)">
          <g
            id="Outlined-_-Content-_-add"
            data-name="Outlined-/-Content-/-add"
            transform="translate(0 54)"
          >
            <g id="Group_193" data-name="Group 193">
              <path
                id="Path"
                d="M0,0H24V24H0Z"
                fill="none"
                fillRule="evenodd"
              />
              <path
                id="_-Icon-Color"
                data-name="🔹-Icon-Color"
                d="M20.125,13.643H13.643v6.482H11.482V13.643H5V11.482h6.482V5h2.161v6.482h6.482Z"
                transform="translate(-0.563 -0.563)"
                fill="currentColor"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const FilterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Icons" transform="translate(3 6)">
      <g id="Outlined" transform="translate(-477 -1488)">
        <g id="Content" transform="translate(100 1428)">
          <g
            id="Outlined-_-Content-_-filter_list"
            data-name="Outlined-/-Content-/-filter_list"
            transform="translate(374 54)"
          >
            <g id="Group_168" data-name="Group 168">
              <path
                id="Path"
                d="M0,0H24V24H0Z"
                fill="none"
                fillRule="evenodd"
              />
              <path
                id="_-Icon-Color"
                data-name="🔹-Icon-Color"
                d="M10,18h4V16H10ZM3,6V8H21V6Zm3,7H18V11H6Z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.414"
    height="20.414"
    viewBox="0 0 20.414 20.414"
    {...props}
  >
    <g
      id="_145609521543238917"
      data-name="145609521543238917"
      transform="translate(-2 -2)"
    >
      <circle
        id="Ellipse_30"
        data-name="Ellipse 30"
        cx="8"
        cy="8"
        r="8"
        transform="translate(3 3)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_81"
        data-name="Line 81"
        x1="4.35"
        y1="4.35"
        transform="translate(16.65 16.65)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39.167"
    viewBox="0 0 39 39.167"
    {...props}
  >
    <defs>
      <filter
        id="ic_mode_edit_24px"
        x="2.667"
        y="5.5"
        width="33.667"
        height="33.667"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.302" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_120" data-name="Group 120" transform="translate(-1713 -623)">
      <circle
        id="Ellipse_29"
        data-name="Ellipse 29"
        cx="19.5"
        cy="19.5"
        r="19.5"
        transform="translate(1713 623)"
        fill="rgba(0,206,255,0.1)"
      />
      <g
        id="List_Group_Position_Standard_Checkbox_Top"
        data-name="List Group/Position Standard/Checkbox/Top"
        transform="translate(1707.667 615.833)"
      >
        <g
          id="ic_mode_edit_24px-2"
          data-name="ic_mode_edit_24px"
          transform="translate(17 18.667)"
        >
          <g
            transform="matrix(1, 0, 0, 1, -11.67, -11.5)"
            filter="url(#ic_mode_edit_24px)"
          >
            <path
              id="ic_mode_edit_24px-3"
              data-name="ic_mode_edit_24px"
              d="M3,15.4v3.263H6.263l9.625-9.625L12.625,5.776ZM18.412,6.515a.867.867,0,0,0,0-1.227L16.376,3.252a.867.867,0,0,0-1.227,0L13.556,4.845,16.82,8.108l1.593-1.593Z"
              transform="translate(8.67 8.5)"
              fill="#00cbff"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39"
    height="39.5"
    viewBox="0 0 39 39.5"
    {...props}
  >
    <defs>
      <filter
        id="ic_delete_24px"
        x="4.556"
        y="5.5"
        width="30.444"
        height="34"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur" />
        <feFlood floodOpacity="0.302" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_119" data-name="Group 119" transform="translate(-1758 -623)">
      <circle
        id="Ellipse_28"
        data-name="Ellipse 28"
        cx="19.5"
        cy="19.5"
        r="19.5"
        transform="translate(1758 623)"
        fill="rgba(255,70,139,0.1)"
      />
      <g
        id="List_Group_Position_Standard_Checkbox_Top"
        data-name="List Group/Position Standard/Checkbox/Top"
        transform="translate(1754.556 617)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 3.44, 6)"
          filter="url(#ic_delete_24px)"
        >
          <path
            id="ic_delete_24px-2"
            data-name="ic_delete_24px"
            d="M5.889,17.222A1.783,1.783,0,0,0,7.667,19h7.111a1.783,1.783,0,0,0,1.778-1.778V6.556H5.889ZM17.444,3.889H14.333L13.444,3H9l-.889.889H5V5.667H17.444Z"
            transform="translate(8.56 8.5)"
            fill="#ff468b"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const LoginArtLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="421.826"
    height="347.391"
    viewBox="0 0 421.826 347.391"
    {...props}
  >
    <g id="Group_215" data-name="Group 215" transform="translate(0 0)">
      <g
        id="Rectangle_84"
        data-name="Rectangle 84"
        transform="matrix(-0.788, -0.616, 0.616, -0.788, 320.782, 180.391)"
        fill="#00cbff"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.34"
      >
        <rect width="182" height="20" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="181" height="19" rx="9.5" fill="none" />
      </g>
      <g
        id="Rectangle_85"
        data-name="Rectangle 85"
        transform="matrix(-0.788, -0.616, 0.616, -0.788, 409.513, 335.707)"
        fill="#bd91ff"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.34"
      >
        <rect width="519.68" height="20" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="518.68" height="19" rx="9.5" fill="none" />
      </g>
      <g
        id="Rectangle_86"
        data-name="Rectangle 86"
        transform="matrix(-0.788, -0.616, 0.616, -0.788, 298.324, 347.391)"
        fill="#ff468b"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.34"
      >
        <rect width="125" height="20" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="124" height="19" rx="9.5" fill="none" />
      </g>
    </g>
  </svg>
);

export const LoginArtRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="421.827"
    height="347.391"
    viewBox="0 0 421.827 347.391"
    {...props}
  >
    <g id="Group_214" data-name="Group 214" transform="translate(0 0)">
      <g
        id="Rectangle_84"
        data-name="Rectangle 84"
        transform="matrix(0.788, 0.616, -0.616, 0.788, 101.045, 167)"
        fill="#00cbff"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.34"
      >
        <rect width="182" height="20" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="181" height="19" rx="9.5" fill="none" />
      </g>
      <g
        id="Rectangle_85"
        data-name="Rectangle 85"
        transform="matrix(0.788, 0.616, -0.616, 0.788, 12.313, 11.684)"
        fill="#bd91ff"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.34"
      >
        <rect width="519.68" height="20" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="518.68" height="19" rx="9.5" fill="none" />
      </g>
      <g
        id="Rectangle_86"
        data-name="Rectangle 86"
        transform="matrix(0.788, 0.616, -0.616, 0.788, 123.503, 0)"
        fill="#ff468b"
        stroke="#707070"
        strokeWidth="1"
        opacity="0.34"
      >
        <rect width="125" height="20" rx="10" stroke="none" />
        <rect x="0.5" y="0.5" width="124" height="19" rx="9.5" fill="none" />
      </g>
    </g>
  </svg>
);

export const ScrollToTopIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="438.533px"
    height="438.533px"
    viewBox="0 0 438.533 438.533"
    enableBackground="new 0 0 438.533 438.533"
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0   c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267   c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407   s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062   C438.533,179.485,428.732,142.795,409.133,109.203z M361.74,259.517l-29.123,29.129c-3.621,3.614-7.901,5.424-12.847,5.424   c-4.948,0-9.236-1.81-12.847-5.424l-87.654-87.653l-87.646,87.653c-3.616,3.614-7.898,5.424-12.847,5.424   c-4.95,0-9.233-1.81-12.85-5.424l-29.12-29.129c-3.617-3.607-5.426-7.898-5.426-12.847c0-4.942,1.809-9.227,5.426-12.848   l129.62-129.616c3.617-3.617,7.898-5.424,12.847-5.424s9.238,1.807,12.846,5.424L361.74,233.822   c3.613,3.621,5.424,7.905,5.424,12.848C367.164,251.618,365.357,255.909,361.74,259.517z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const XCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x-circle"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="15" y1="9" x2="9" y2="15" />
    <line x1="9" y1="9" x2="15" y2="15" />
  </svg>
);

export const XIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-x"
    {...props}
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
);

export const DashCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-minus-circle"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

export const CheckIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512px"
    height="512px"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill="currentColor"
      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
    />
  </svg>
);

export const RefreshIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 56.32" {...props}>
    <defs></defs>
    <title>Asset 36</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M63.22,22.34a2.57,2.57,0,0,0-3.62.06l-3.39,3.52a28.13,28.13,0,1,0-7.94,21.95,2.56,2.56,0,0,0-3.66-3.58,23,23,0,1,1,6.47-18.45l-3.19-3.19a2.56,2.56,0,1,0-3.62,3.62L52,34a2.56,2.56,0,0,0,1.81.75h0a2.56,2.56,0,0,0,1.82-.79L63.28,26A2.57,2.57,0,0,0,63.22,22.34Z"
        />
      </g>
    </g>
  </svg>
);

export const SeeAllIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 113.03"
    enableBackground="new 0 0 122.88 113.03"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M36.9,23.5h71.13c8.17,0,14.85,6.69,14.85,14.85v59.83c0,8.17-6.69,14.85-14.85,14.85H36.9 c-8.17,0-14.85-6.68-14.85-14.85V38.35C22.05,30.19,28.73,23.5,36.9,23.5L36.9,23.5z M10.08,73.96c0,2.78-2.26,5.04-5.04,5.04 C2.26,79,0,76.74,0,73.96V19.89C0,14.42,2.24,9.44,5.84,5.84C9.44,2.24,14.42,0,19.89,0h65.37c2.78,0,5.04,2.26,5.04,5.04 c0,2.78-2.26,5.04-5.04,5.04H19.89c-2.69,0-5.15,1.1-6.93,2.88c-1.78,1.78-2.88,4.23-2.88,6.93V73.96L10.08,73.96z M84.6,56.42 l0.08-0.08c0.91-0.86,2.09-1.3,3.26-1.3c1.2,0,2.41,0.46,3.32,1.38c0.02,0.01,0.06,0.06,0.08,0.08c0.86,0.91,1.3,2.08,1.29,3.26 c0,1.19-0.45,2.38-1.35,3.29C86.61,68.17,80.72,75.5,75.86,80c-1.01,0.98-2.21,1.48-3.42,1.48c-1.24,0-2.46-0.51-3.51-1.55 c-3.53-2.85-11.68-12.89-15.29-16.87c-0.91-0.92-1.36-2.14-1.35-3.34c0.01-1.21,0.48-2.41,1.4-3.32c0.73-0.72,1.65-1.16,2.59-1.3 c0.97-0.15,1.98,0.01,2.87,0.47c0.12,0.06,0.22,0.14,0.3,0.23l13.07,14.22c2.21-2.28,3.86-4.27,5.5-6.26 C79.93,61.46,81.84,59.16,84.6,56.42L84.6,56.42z"
      />
    </g>
  </svg>
);

export const LoadingIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Icons"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    {...props}
  >
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="16"
      y1="3"
      x2="16"
      y2="8"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="6.8"
      y1="6.8"
      x2="10.3"
      y2="10.3"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="3"
      y1="16"
      x2="8"
      y2="16"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="6.8"
      y1="25.2"
      x2="10.3"
      y2="21.7"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="16"
      y1="29"
      x2="16"
      y2="24"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="25.2"
      y1="25.2"
      x2="21.7"
      y2="21.7"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="29"
      y1="16"
      x2="24"
      y2="16"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      x1="25.2"
      y1="6.8"
      x2="21.7"
      y2="10.3"
    />
  </svg>
);

export const TickIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-check"
    {...props}
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export const DetailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    enableBackground="new 0 0 512 512"
    {...props}
  >
    <title>document-and-pen-1-outline</title>
    <path
      fill="currentColor"
      d="M482.6,250.4l18.1-18.1c15-15,15-39.3,0-54.3c-15-15-39.3-15-54.3,0l-36.9,36.9v-61.3L256.1,0H64  C28.7,0,0,28.7,0,64v384c0,35.3,28.7,64,64,64h281.6c35.3,0,64-28.7,64-64V323.5l55-55l9,9l-18.1,18.1c-5.1,4.9-5.2,13-0.3,18.1  c4.9,5.1,13,5.2,18.1,0.3c0.1-0.1,0.2-0.2,0.3-0.3l27.2-27.2c5-5,5-13.1,0-18.1L482.6,250.4z M268.8,49.1l91.5,91.7h-53.1  c-21.2,0-38.4-17.2-38.4-38.4L268.8,49.1z M384,448c0,21.2-17.2,38.4-38.4,38.4H64c-21.2,0-38.4-17.2-38.4-38.4V64  c0-21.2,17.2-38.4,38.4-38.4h179.2v76.8c0,35.3,28.7,64,64,64c0,0,0,0,0,0H384v74.1L265.4,359c-11.5,11.5-14.5,29-7.4,43.6l-1.1,1.1  c-13.8-14.8-27.3-21.3-40.9-19.6c-13,1.6-20.8,10-27.1,16.8c-4.7,5.1-7.8,8.3-11.3,8.8c-1.5-0.1-5.5-4.6-7.9-7.4  c-5.9-7-14-16.5-27.5-18.2c-16.7-2-33,8-50.1,30.6c-4.4,5.6-3.4,13.6,2.2,18c5.6,4.4,13.6,3.4,18-2.2c0.1-0.1,0.2-0.2,0.3-0.3  c10.5-13.9,20.2-21.5,26.7-20.6c3.2,0.4,6.5,3.9,11,9.3c6,7,14,16.5,27,16.5c1.1,0,2.3-0.1,3.4-0.2c13-1.6,20.8-10,27.1-16.8  c4.8-5.2,7.8-8.3,11.3-8.8c6.4-0.9,16.1,6.8,26.7,20.6c2.4,3.1,6.1,5,10.1,5c0.2,0,0.5,0,0.7,0h0.5c3.2-0.1,6.2-1.5,8.4-3.7  l10.7-10.7c14.6,7.2,32.2,4.2,43.6-7.4l64.4-64.3V448z M301.6,395.2c-5,5-13.1,5-18.1,0l0,0c-5-5-5-13.1,0-18.1l144.8-144.8  l18.1,18.1L301.6,395.2z M464.5,232.3l-3.3-3.3l-14.8-14.8l18.1-18.1c1-0.9,2.1-1.7,3.3-2.3c1.1-0.6,2.3-1,3.5-1.2  c0.7-0.1,1.5-0.2,2.2-0.2c0.8,0,1.5,0.1,2.3,0.3c1.5,0.3,2.9,0.8,4.2,1.5c6.1,3.6,8.1,11.5,4.4,17.6c-0.5,0.9-1.2,1.7-1.9,2.5  L464.5,232.3z M89.6,192c0-7.1,5.7-12.8,12.8-12.8c0,0,0,0,0,0h77.2c7.1,0,12.8,5.7,12.8,12.8s-5.7,12.8-12.8,12.8h-77.2  C95.3,204.8,89.6,199.1,89.6,192L89.6,192z M89.6,243.2c0-7.1,5.7-12.8,12.8-12.8c0,0,0,0,0,0h154c7.1,0,12.8,5.7,12.8,12.8  s-5.7,12.8-12.8,12.8h-154C95.3,256,89.6,250.3,89.6,243.2z"
    />
  </svg>
);

export const PlusCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-plus-circle"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="12" y1="8" x2="12" y2="16" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

export const MinusCircleIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-minus-circle"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

export const AdminIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-user-check"
    {...props}
  >
    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
    <circle cx="8.5" cy="7" r="4" />
    <polyline points="17 11 19 13 23 9" />
  </svg>
);

export const UserIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-user"
    {...props}
  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
    <circle cx="12" cy="7" r="4" />
  </svg>
);

export const TwoUsersIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-users"
    {...props}
  >
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
  </svg>
);

export const UserProfile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39.998"
    viewBox="0 0 40 39.998"
    {...props}
  >
    <g id="profile-user-svgrepo-com" transform="translate(0 -0.001)">
      <path
        id="Path_16"
        data-name="Path 16"
        d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,5.98A6.615,6.615,0,1,1,13.386,12.6,6.616,6.616,0,0,1,20,5.981ZM20,34.77a14.678,14.678,0,0,1-9.558-3.525A2.819,2.819,0,0,1,9.448,29.1a6.671,6.671,0,0,1,6.7-6.67h7.7a6.662,6.662,0,0,1,6.693,6.67,2.812,2.812,0,0,1-.988,2.142A14.673,14.673,0,0,1,20,34.77Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const PrinterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Icons"
    x="0px"
    y="0px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    {...props}
  >
    <rect
      x="7"
      y="19"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      width="18"
      height="10"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      points="19,3 19,9 25,9 19,3 7,3 7,14 25,14 25,9 "
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M25,10h2c1.1,0,2,0.9,2,2v11h-4"
    />
    <path
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M7,10H5c-1.1,0-2,0.9-2,2v11h4"
    />
  </svg>
);

export const ReportIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 128 128"
    {...props}
  >
    <path
      fill="currentColor"
      d="M28.628,72.11907A17.97418,17.97418,0,0,1,6.59139,80.13388v38.54969a2.49475,2.49475,0,0,0,2.49111,2.49111H26.35031a2.49477,2.49477,0,0,0,2.49109-2.49111v-46.51A.11355.11355,0,0,0,28.628,72.11907Z"
    />
    <path
      fill="currentColor"
      d="M46.2281,42.16451,37.447,51.07585v67.60772a2.49475,2.49475,0,0,0,2.49111,2.49111H57.20588a2.49477,2.49477,0,0,0,2.49111-2.49111v-72.822L55.99432,42.156A18.13389,18.13389,0,0,1,46.2281,42.16451Z"
    />
    <path
      fill="currentColor"
      d="M68.30258,67.084v51.59962a2.49474,2.49474,0,0,0,2.49109,2.49111H88.06148a2.49477,2.49477,0,0,0,2.49111-2.49111V68.02379a17.94923,17.94923,0,0,1-22.25-.93984Z"
    />
    <path
      fill="currentColor"
      d="M110.14729,35.7358,99.15817,46.72493v71.95864a2.49474,2.49474,0,0,0,2.49109,2.49111h17.26781a2.49477,2.49477,0,0,0,2.49111-2.49111v-83.429a17.95629,17.95629,0,0,1-11.26088.48124Z"
    />
    <path
      fill="currentColor"
      d="M115.045,6.82533a11.60217,11.60217,0,0,0-9.725,17.94326l-18.96395,18.964a11.5781,11.5781,0,0,0-12.86295.13029L60.81946,31.18721a11.61122,11.61122,0,1,0-19.45139-.00064L19.28566,53.59035A11.62772,11.62772,0,1,0,22.67974,56.984l22.082-22.40314a11.572,11.572,0,0,0,12.6643,0L70.16978,47.32633a11.61164,11.61164,0,1,0,19.58-.1997l18.9646-18.9646A11.60839,11.60839,0,1,0,115.045,6.82533Z"
    />
  </svg>
);

export const PdfIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 128 128"
    version="1.1"
    viewBox="0 0 128 128"
    {...props}
  >
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g>
      <g>
        <g>
          <g>
            <path
              d="M104,126H24c-5.514,0-10-4.486-10-10V12c0-5.514,4.486-10,10-10h40.687      c2.671,0,5.183,1.041,7.07,2.929l39.314,39.314c1.889,1.889,2.929,4.399,2.929,7.07V116C114,121.514,109.514,126,104,126z M24,6      c-3.309,0-6,2.691-6,6v104c0,3.309,2.691,6,6,6h80c3.309,0,6-2.691,6-6V51.313c0-1.579-0.641-3.125-1.757-4.242L68.929,7.757      C67.796,6.624,66.289,6,64.687,6H24z"
              fill="currentColor"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M95.21,80.32c-0.07-0.51-0.48-1.15-0.92-1.58c-1.26-1.24-4.03-1.89-8.25-1.95      c-2.86-0.03-6.3,0.22-9.92,0.73c-1.62-0.93-3.29-1.95-4.6-3.18c-3.53-3.29-6.47-7.86-8.31-12.89c0.12-0.47,0.22-0.88,0.32-1.3      c0,0,1.98-11.28,1.46-15.1c-0.07-0.52-0.12-0.67-0.26-1.08l-0.17-0.44c-0.54-1.25-1.6-2.57-3.26-2.5L60.32,41H60.3      c-1.86,0-3.36,0.95-3.76,2.36c-1.2,4.44,0.04,11.09,2.29,19.69l-0.58,1.4c-1.61,3.94-3.63,7.9-5.41,11.39l-0.23,0.45      c-1.88,3.67-3.58,6.79-5.13,9.43l-1.59,0.84c-0.12,0.06-2.85,1.51-3.49,1.89c-5.43,3.25-9.03,6.93-9.63,9.85      c-0.19,0.94-0.05,2.13,0.92,2.68l1.54,0.78c0.67,0.33,1.38,0.5,2.1,0.5c3.87,0,8.36-4.82,14.55-15.62      c7.14-2.32,15.28-4.26,22.41-5.32c5.43,3.05,12.11,5.18,16.33,5.18c0.75,0,1.4-0.07,1.92-0.21c0.81-0.22,1.49-0.68,1.91-1.3      C95.27,83.76,95.43,82.06,95.21,80.32z M36.49,99.33c0.7-1.93,3.5-5.75,7.63-9.13c0.26-0.21,0.9-0.81,1.48-1.37      C41.28,95.72,38.39,98.46,36.49,99.33z M60.95,43c1.24,0,1.95,3.13,2.01,6.07c0.06,2.94-0.63,5-1.48,6.53      c-0.71-2.26-1.05-5.82-1.05-8.15C60.43,47.45,60.38,43,60.95,43z M53.65,83.14c0.87-1.55,1.77-3.19,2.69-4.92      c2.25-4.25,3.67-7.57,4.72-10.3c2.1,3.82,4.72,7.07,7.79,9.67c0.39,0.32,0.8,0.65,1.22,0.98C63.82,79.8,58.41,81.31,53.65,83.14      z M93.08,82.79c-0.38,0.23-1.47,0.37-2.17,0.37c-2.26,0-5.07-1.03-9-2.72c1.51-0.11,2.9-0.17,4.14-0.17      c2.27,0,2.94-0.01,5.17,0.56C93.44,81.4,93.47,82.55,93.08,82.79z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export const PaperIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 64" {...props}>
    <title>Asset 6</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="currentColor"
          d="M47.79,15a2.68,2.68,0,0,0-.57-.87L33.89.78A2.68,2.68,0,0,0,33,.21,2.59,2.59,0,0,0,32,0H2.67A2.66,2.66,0,0,0,0,2.67V61.33A2.66,2.66,0,0,0,2.67,64H45.33A2.66,2.66,0,0,0,48,61.33V16A2.59,2.59,0,0,0,47.79,15ZM34.67,9.1l4.23,4.23H34.67Zm8,49.57H5.33V5.33h24V16A2.68,2.68,0,0,0,32,18.67H42.67Z"
        />
        <path
          fill="currentColor"
          d="M34.67,32H13.33a2.67,2.67,0,0,1,0-5.33H34.67a2.67,2.67,0,0,1,0,5.33Z"
        />
        <path
          fill="currentColor"
          d="M21.33,21.33h-8a2.67,2.67,0,0,1,0-5.33h8a2.67,2.67,0,1,1,0,5.33Z"
        />
        <path
          fill="currentColor"
          d="M34.67,42.67H13.33a2.67,2.67,0,0,1,0-5.34H34.67a2.67,2.67,0,0,1,0,5.34Z"
        />
        <path
          fill="currentColor"
          d="M34.67,53.33H13.33a2.67,2.67,0,0,1,0-5.33H34.67a2.67,2.67,0,0,1,0,5.33Z"
        />
      </g>
    </g>
  </svg>
);

export const CurvedArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width="10"
    height="10"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.146 5.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L12.793 9l-2.647-2.646a.5.5 0 0 1 0-.708z"
    ></path>
    <path
      fillRule="evenodd"
      d="M3 2.5a.5.5 0 0 0-.5.5v4A2.5 2.5 0 0 0 5 9.5h8.5a.5.5 0 0 0 0-1H5A1.5 1.5 0 0 1 3.5 7V3a.5.5 0 0 0-.5-.5z"
    ></path>
  </svg>
);

export const SunIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    width="24px"
    height="24px"
    {...props}
  >
    <path d="M 11 0 L 11 3 L 13 3 L 13 0 L 11 0 z M 4.2226562 2.8085938 L 2.8085938 4.2226562 L 4.9296875 6.34375 L 6.34375 4.9296875 L 4.2226562 2.8085938 z M 19.777344 2.8085938 L 17.65625 4.9296875 L 19.070312 6.34375 L 21.191406 4.2226562 L 19.777344 2.8085938 z M 12 5 A 7 7 0 0 0 5 12 A 7 7 0 0 0 12 19 A 7 7 0 0 0 19 12 A 7 7 0 0 0 12 5 z M 0 11 L 0 13 L 3 13 L 3 11 L 0 11 z M 21 11 L 21 13 L 24 13 L 24 11 L 21 11 z M 4.9296875 17.65625 L 2.8085938 19.777344 L 4.2226562 21.191406 L 6.34375 19.070312 L 4.9296875 17.65625 z M 19.070312 17.65625 L 17.65625 19.070312 L 19.777344 21.191406 L 21.191406 19.777344 L 19.070312 17.65625 z M 11 21 L 11 24 L 13 24 L 13 21 L 11 21 z" />
  </svg>
);

export const MoonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="45.74px"
    height="45.74px"
    viewBox="0 0 45.74 45.74"
    enableBackground="new 0 0 45.74 45.74"
    {...props}
    fill="currentColor"
  >
    <g>
      <g>
        <g>
          <g>
            <g>
              <path d="M12.2,15.267c0-3.322,0.896-6.43,2.448-9.113c0.376-0.649-0.191-1.451-0.918-1.265       C5.35,7.041-0.711,14.961,0.067,24.179c0.736,8.701,7.768,15.803,16.463,16.617c6.033,0.565,11.517-1.811,15.221-5.848       c0.5-0.546,0.073-1.432-0.666-1.401c-0.196,0.009-0.395,0.013-0.594,0.013C20.389,33.56,12.2,25.371,12.2,15.267z" />
            </g>
          </g>
          <g>
            <path d="M23.144,5.6l0.917,2.633l2.788,0.061c0.354,0.008,0.498,0.456,0.218,0.669l-2.221,1.687l0.805,2.669      c0.104,0.338-0.279,0.614-0.568,0.414l-2.292-1.59l-2.29,1.59c-0.29,0.201-0.669-0.076-0.569-0.414l0.805-2.669l-2.221-1.687      c-0.28-0.213-0.134-0.661,0.218-0.669l2.788-0.061L22.44,5.6C22.556,5.267,23.027,5.267,23.144,5.6z" />
          </g>
          <g>
            <path d="M40.26,26.44l0.881,2.53l2.679,0.057c0.389,0.009,0.549,0.502,0.24,0.737l-2.134,1.621l0.772,2.564      c0.111,0.371-0.309,0.677-0.627,0.454l-2.201-1.527l-2.2,1.527c-0.318,0.223-0.739-0.083-0.626-0.454l0.772-2.564l-2.133-1.621      c-0.31-0.234-0.149-0.729,0.238-0.737l2.679-0.057l0.884-2.53C39.612,26.074,40.131,26.074,40.26,26.44z" />
          </g>
          <g>
            <path d="M39.926,8.098l1.325,3.799l4.021,0.086c0.452,0.01,0.639,0.584,0.278,0.858l-3.204,2.434l1.162,3.853      c0.131,0.433-0.356,0.788-0.729,0.529l-3.306-2.294l-3.305,2.294c-0.372,0.259-0.86-0.097-0.729-0.529l1.161-3.853l-3.205-2.434      c-0.358-0.273-0.172-0.849,0.279-0.858l4.022-0.086l1.324-3.799C39.173,7.672,39.776,7.672,39.926,8.098z" />
          </g>
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const HistoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21px"
    height="18px"
    viewBox="0 0 21 18"
    version="1.1"
    {...props}
  >
    <title>history</title>
    <desc>Created with Sketch.</desc>
    <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Rounded" transform="translate(-441.000000, -289.000000)">
        <g id="Action" transform="translate(100.000000, 100.000000)">
          <g
            id="-Round-/-Action-/-history"
            transform="translate(340.000000, 186.000000)"
          >
            <g transform="translate(0.000000, 0.000000)">
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <path
                d="M13.26,3 C8.17,2.86 4,6.95 4,12 L2.21,12 C1.76,12 1.54,12.54 1.86,12.85 L4.65,15.65 C4.85,15.85 5.16,15.85 5.36,15.65 L8.15,12.85 C8.46,12.54 8.24,12 7.79,12 L6,12 C6,8.1 9.18,4.95 13.1,5 C16.82,5.05 19.95,8.18 20,11.9 C20.05,15.81 16.9,19 13,19 C11.39,19 9.9,18.45 8.72,17.52 C8.32,17.21 7.76,17.24 7.4,17.6 C6.98,18.02 7.01,18.73 7.48,19.09 C9,20.29 10.91,21 13,21 C18.05,21 22.14,16.83 22,11.74 C21.87,7.05 17.95,3.13 13.26,3 Z M12.75,8 C12.34,8 12,8.34 12,8.75 L12,12.43 C12,12.78 12.19,13.11 12.49,13.29 L15.61,15.14 C15.97,15.35 16.43,15.23 16.64,14.88 C16.85,14.52 16.73,14.06 16.38,13.85 L13.5,12.14 L13.5,8.74 C13.5,8.34 13.16,8 12.75,8 Z"
                id="🔹Icon-Color"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CopyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 330 330"
    enableBackground="new 0 0 330 330"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15   c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95   c-8.284,0-15,6.716-15,15v165H50V30z" />
      <path d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z" />
      <path d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z" />
      <path d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z   " />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export const TestIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    {...props}
  >
    <title>icon 40 clipboard list</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon-40-clipboard-list" fill="currentColor">
        <path
          d="M12,5 L12,3.99791312 C12,2.89826062 12.897616,2 14.0048815,2 L14.9951185,2 C16.1061002,2 17,2.89449617 17,3.99791312 L17,5 L19.0044225,5 C19.555163,5 20,5.44724809 20,5.99895656 L20,7.00104344 C20,7.55733967 19.5542648,8 19.0044225,8 L9.9955775,8 C9.44483697,8 9,7.55275191 9,7.00104344 L9,5.99895656 C9,5.44266033 9.44573523,5 9.9955775,5 L12,5 L12,5 Z M11.0000005,4 L10.0027601,4 C8.89828672,4 8.00262776,4.89128252 8.00000577,6 L6.99742191,6 C5.89427625,6 5,6.88976324 5,8.00359486 L5,28.9964051 C5,30.10296 5.89092539,31 6.99742191,31 L22.0025781,31 C23.1057238,31 24,30.1102368 24,28.9964051 L24,8.00359486 C24,6.89703997 23.1090746,6 22.0025781,6 L20.9999942,6 L20.9999942,6 C20.9973726,4.89497907 20.1048269,4 18.9972399,4 L17.9999995,4 C17.9990959,2.34299141 16.6497738,1 14.9907455,1 L14.0092545,1 C12.3478441,1 11.0008998,2.33812603 11.0000005,4 L11.0000005,4 L11.0000005,4 Z M20.9999942,7 L22.0000398,7 C22.5452911,7 23,7.44908998 23,8.00307055 L23,28.9969294 C23,29.5610822 22.5523026,30 22.0000398,30 L6.9999602,30 C6.45470893,30 6,29.55091 6,28.9969294 L6,8.00307055 C6,7.43891776 6.44769743,7 6.9999602,7 L8.00000579,7 C8.00262739,8.10502093 8.89517314,9 10.0027601,9 L18.9972399,9 C20.1017133,9 20.9973722,8.10871748 20.9999942,7 L20.9999942,7 L20.9999942,7 Z M14.5,5 C14.7761424,5 15,4.77614239 15,4.5 C15,4.22385761 14.7761424,4 14.5,4 C14.2238576,4 14,4.22385761 14,4.5 C14,4.77614239 14.2238576,5 14.5,5 L14.5,5 Z M13,14 L13,15 L21,15 L21,14 L13,14 L13,14 Z M8,13 L8,16 L11,16 L11,13 L8,13 L8,13 Z M9,14 L9,15 L10,15 L10,14 L9,14 L9,14 Z M8,18 L8,21 L11,21 L11,18 L8,18 L8,18 Z M9,19 L9,20 L10,20 L10,19 L9,19 L9,19 Z M13,19 L13,20 L21,20 L21,19 L13,19 L13,19 Z M8,23 L8,26 L11,26 L11,23 L8,23 L8,23 Z M9,24 L9,25 L10,25 L10,24 L9,24 L9,24 Z M13,24 L13,25 L21,25 L21,24 L13,24 L13,24 Z"
          id="clipboard-list"
        />
      </g>
    </g>
  </svg>
);
